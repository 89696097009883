import { IconInfo, Tooltip } from '@leland-dev/leland-ui-library';
import React, {
  type InputHTMLAttributes,
  type MouseEventHandler,
  type ReactElement,
  type ReactNode,
} from 'react';
import type { FieldError, UseFormRegisterReturn } from 'react-hook-form';

import { ErrorMessage } from '../forms/Inputs';

// by default numeric inputs allow scientific notation, thus ignore "e"
const IGNORED_NUMERIC_KEYS = new Set(['E', 'e', '+', '-', '.']);
const IGNORED_NUMERIC_KEYS_MINUS_DECIMAL = new Set(['E', 'e', '+', '-']);

type MaxLengthProps =
  | { maxLength: number; currentLength: number }
  | { maxLength?: never; currentLength?: never };

type InfoButtonProps = { label: string } & (
  | { href: string; onClick?: never }
  | { href?: never; onClick: MouseEventHandler }
);

const DISABLED_CLASSNAME = 'button--disabled';

type InputProps = Omit<InputHTMLAttributes<HTMLInputElement>, 'maxLength'> &
  MaxLengthProps & {
    label: string;
    subLabel?: ReactNode;
    info?: InfoButtonProps;
    infoInSubLabel?: boolean;
    flag?: string;
    className?: string;
    inputContainerClassName?: string;
    labelIsHidden?: boolean;
    register?: UseFormRegisterReturn;
    error?: FieldError;
    tooltip?: string;
    prefix?: string;
    subtlePrefix?: string;
    postfix?: string;
    inputIcon?: ReactElement;
    allowDecimal?: boolean;
  };

const Input: React.FC<InputProps> = ({
  label,
  subLabel,
  info,
  infoInSubLabel = false,
  flag,
  className,
  inputContainerClassName = '',
  labelIsHidden,
  register,
  onChange,
  onKeyDown,
  error,
  tooltip,
  prefix,
  subtlePrefix,
  postfix,
  inputIcon,
  disabled,
  currentLength,
  allowDecimal,
  ...inputProps
}) => {
  const { maxLength, type } = inputProps;
  const isNumericInput = type === 'number';
  return (
    <div className={className}>
      {!labelIsHidden || subLabel ? (
        <div className="mb-2">
          {!labelIsHidden ? (
            <div className="flex w-full justify-between space-x-1">
              <label>
                <span className="text-base font-normal text-leland-gray-dark">
                  {label}
                </span>
                {flag ? (
                  <span className="ml-2 text-base font-normal text-leland-gray-extra-light">
                    ({flag})
                  </span>
                ) : null}
                {tooltip ? (
                  <Tooltip content={tooltip}>
                    <IconInfo className="ml-1 size-4 text-leland-gray-extra-light" />
                  </Tooltip>
                ) : null}
              </label>
              {!infoInSubLabel ? renderInfo(info) : null}
            </div>
          ) : null}
          {subLabel ? (
            <div className="flex flex-wrap items-center space-x-1">
              <label className="text-base font-normal text-leland-gray-light">
                {subLabel}
              </label>
              {infoInSubLabel ? renderInfo(info) : null}
            </div>
          ) : null}
        </div>
      ) : null}
      <div
        className={`flex items-center overflow-hidden rounded-lg border border-leland-gray-stroke transition duration-100 focus-within:border-leland-gray-extra-light ${inputContainerClassName}`}
      >
        {inputIcon ? <div className="pl-4">{inputIcon}</div> : null}
        {prefix ? (
          <div className="pointer-events-none whitespace-nowrap bg-gray-100 p-4 text-base text-leland-gray-light">
            <span>{prefix}</span>
          </div>
        ) : null}
        {subtlePrefix ? (
          <div
            className={`pointer-events-none whitespace-nowrap py-3 pl-4 pr-0.5 text-base ${
              disabled ? DISABLED_CLASSNAME : ''
            }`}
          >
            <span>{subtlePrefix}</span>
          </div>
        ) : null}
        <input
          className={`block w-full rounded-lg bg-white p-4 shadow-none outline-none outline-0 ring-0 placeholder:text-gray-400 ${
            postfix ? 'rounded-r-none' : ''
          } ${prefix ? 'rounded-l-none' : ''} ${
            subtlePrefix ? 'rounded-l-none pl-0' : ''
          } ${disabled ? DISABLED_CLASSNAME : ''}`}
          {...inputProps}
          {...register}
          disabled={disabled}
          onChange={(e) => {
            void register?.onChange(e);
            onChange?.(e);
          }}
          onKeyDown={
            isNumericInput || onKeyDown
              ? (e) => {
                  if (
                    isNumericInput &&
                    (allowDecimal
                      ? IGNORED_NUMERIC_KEYS_MINUS_DECIMAL
                      : IGNORED_NUMERIC_KEYS
                    ).has(e.key)
                  ) {
                    e.preventDefault();
                  }
                  onKeyDown?.(e);
                }
              : undefined
          }
          onWheel={isNumericInput ? (e) => e.currentTarget.blur() : undefined}
        />
        {postfix ? (
          <div className="pointer-events-none whitespace-nowrap bg-gray-100 p-4 text-base text-leland-gray-light">
            <span>{postfix}</span>
          </div>
        ) : null}
      </div>
      <div className="flex w-full">
        <div className="flex-1">
          {error?.message ? (
            <ErrorMessage errorMessage={error.message} />
          ) : null}
        </div>
        {maxLength ? (
          <p
            className={`mt-1 text-sm ${
              (currentLength ?? 0) > maxLength
                ? 'text-leland-red'
                : 'text-leland-gray-light'
            }`}
          >
            {currentLength}/{maxLength}
          </p>
        ) : null}
      </div>
    </div>
  );
};

const INFO_CLASSNAME =
  'font-medium text-leland-primary text-base whitespace-nowrap';
const renderInfo = (info?: InfoButtonProps) => {
  if (!info) {
    return null;
  }

  const { label, href, onClick } = info;

  if (href) {
    return (
      <a
        className={INFO_CLASSNAME}
        href={href}
        target="_blank"
        rel="noreferrer"
      >
        {label}
      </a>
    );
  }

  return (
    <button type="button" className={INFO_CLASSNAME} onClick={onClick}>
      {label}
    </button>
  );
};

export default Input;
