import { debounce, type DebouncedFunc } from 'lodash-es';
import { useEffect, useMemo, useRef, useState } from 'react';

/**
 * Creates a memoized debounced callback of the given function
 * @param fn Function to be debounced (Note: MUST be a memoized function, i.e. `useCallback`)
 * @param wait Number of milliseconds to delay
 * @param flushOnUnmount (optional) Whether or not to flush the debounced function on unmount
 * @returns Debounced function
 */
export const useDebouncedCallback = <P extends (...args: unknown[]) => unknown>(
  fn: P,
  wait: number,
  flushOnUnmount = false,
): DebouncedFunc<P> => {
  const debouncedFn = useMemo(() => debounce(fn, wait), [fn, wait]);

  const unmountCallback = useRef<() => void>(undefined);

  useEffect(() => {
    unmountCallback.current = () => {
      if (flushOnUnmount) {
        debouncedFn.flush();
      }
    };
  }, [debouncedFn, flushOnUnmount]);

  useEffect(() => () => unmountCallback.current?.(), []);

  return debouncedFn;
};

/**
 * Debounced update for a given value
 * @param value Current value
 * @param wait Number of milliseconds to delay the update
 * @returns The debounced updated value
 */
export const useDebounce = <P>(value: P, wait: number): P => {
  const [debouncedValue, setDebouncedValue] = useState<P>(value);

  const debouncedUpdate = useDebouncedCallback(setDebouncedValue, wait);

  useEffect(() => {
    debouncedUpdate(value);
  }, [debouncedUpdate, value]);

  return debouncedValue;
};
