import {
  BrandLelandApp,
  BrandLelandSilhouette,
} from '@leland-dev/leland-ui-library';
import Image from 'next/image';
import Link from 'next/link';
import React, { type PropsWithChildren } from 'react';

import AuthImage from '../assets/images/auth.jpg';

type Props = PropsWithChildren<{
  topRightLink?: React.ReactNode;
}>;

export default function AuthPageLayout({
  children,
  topRightLink,
}: Props): React.ReactElement {
  return (
    <div className="grid h-full grid-cols-12">
      <div className="relative col-span-12 hidden h-60 bg-cover bg-center p-10 lg:col-span-4 lg:block lg:h-full lg:min-h-screen lg:p-8">
        <Image
          src={AuthImage}
          className="z-0 object-cover object-center"
          alt=""
          fill
          sizes="(min-width: 1024px) 40vw, 100vw"
        />
        <Link
          href="/"
          className="fixed left-6 top-6 z-10 block rounded-full bg-white p-2 shadow-lg"
        >
          <BrandLelandSilhouette className="size-7 text-leland-primary" />
        </Link>
      </div>
      <Link
        href="/"
        className="col-span-12 mt-20 block place-self-center rounded-full shadow-lg lg:hidden"
      >
        <BrandLelandApp className="size-11 rounded-lg" />
      </Link>
      <div className="col-span-12 flex flex-col items-start p-8 lg:col-span-8 lg:justify-center lg:p-20">
        <div className="w-3/4 max-w-md place-self-center">{children}</div>
        {topRightLink ? (
          <div className="right-5 top-4 mt-8 hidden lg:fixed lg:mt-0 lg:block">
            {topRightLink}
          </div>
        ) : null}
      </div>
    </div>
  );
}
