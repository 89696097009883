import Cookies from 'js-cookie';

import type { FirstContactInput } from '../__generated-gql-types__/globalTypes';

export const getAttributionParams = (): FirstContactInput => ({
  gclid: Cookies.get('gclid'),
  source: Cookies.get('source'),
  medium: Cookies.get('medium'),
  campaign: Cookies.get('campaign'),
  referrer: Cookies.get('referrer'),
});
