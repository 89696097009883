import { IconInfo, Tooltip } from '@leland-dev/leland-ui-library';
import React, {
  type ChangeEventHandler,
  type InputHTMLAttributes,
  type MouseEventHandler,
  type ReactElement,
  type ReactNode,
} from 'react';
import { type FieldError, type UseFormRegisterReturn } from 'react-hook-form';

export interface InputOption {
  value: string | number;
  label: string;
  subLabel?: string;
}

interface CheckboxProps {
  label: string;
  subLabel?: string;
  className?: string;
  inputClassName?: string;
  itemsClassName?: string;
  register: UseFormRegisterReturn;
  options: InputOption[];
  error?: FieldError;
}

export const Checkbox = ({
  label,
  subLabel,
  className,
  inputClassName,
  itemsClassName,
  register,
  options,
  error,
}: CheckboxProps): ReactElement => {
  const checkboxItems = options.map((option) => {
    return (
      <label
        key={`${register.name}-${option.value}`}
        className={`mt-1 flex cursor-pointer items-center text-base font-normal text-leland-gray-dark ${className}`}
      >
        <input
          className={`form-checkbox mr-2 rounded ${inputClassName}`}
          type="checkbox"
          value={option.value}
          {...register}
        />
        {option.label}
      </label>
    );
  });

  return (
    <fieldset>
      <legend className="text-base font-normal text-leland-gray-dark">
        {label}
        {subLabel ? (
          <p className="mb-2 mt-1 font-normal text-leland-gray-light">
            {subLabel}
          </p>
        ) : null}
      </legend>
      <div className={itemsClassName}>{checkboxItems}</div>
      {error?.message ? <ErrorMessage errorMessage={error.message} /> : null}
    </fieldset>
  );
};

type InfoButtonProps = { label: string } & (
  | { href: string; onClick?: never }
  | { href?: never; onClick: MouseEventHandler }
);

type MaxLengthProps =
  | { maxLength: number; currentLength: number }
  | { maxLength?: never; currentLength?: never };

type TextareaProps = Omit<InputHTMLAttributes<HTMLInputElement>, 'maxLength'> &
  MaxLengthProps & {
    label: ReactNode;
    subLabel?: string;
    labelClass?: string;
    labelIsHidden?: boolean;
    flag?: string;
    info?: InfoButtonProps;
    infoInSubLabel?: boolean;
    className?: string;
    inputClassName?: string;
    defaultText?: string;
    register: UseFormRegisterReturn;
    rows: number;
    placeholder?: string;
    error?: FieldError;
    tooltip?: string;
    resizeX?: boolean;
    resizeY?: boolean;
    subtlePrefix?: string;
    onChange?: ChangeEventHandler<HTMLTextAreaElement>;
  };

export const Textarea = ({
  label,
  subLabel,
  labelClass,
  labelIsHidden,
  flag,
  info,
  infoInSubLabel = false,
  className,
  inputClassName,
  defaultText,
  register,
  placeholder,
  rows,
  error,
  tooltip,
  resizeX,
  resizeY,
  subtlePrefix,
  onChange,
  currentLength,
  ...inputProps
}: TextareaProps): ReactElement => {
  const { maxLength } = inputProps;

  return (
    <div className={className}>
      <label>
        {!labelIsHidden ? (
          <div className="flex w-full justify-between space-x-1">
            <label className="flex flex-1 items-center">
              <span
                className={`text-base font-normal text-leland-gray-dark ${
                  labelClass ?? ''
                }`}
              >
                {label}
              </span>
              {flag ? (
                <span className="ml-2 text-base font-normal text-leland-gray-extra-light">
                  {flag}
                </span>
              ) : null}
              {tooltip != null && (
                <Tooltip content={tooltip}>
                  <IconInfo className="ml-1 size-4 text-leland-gray-extra-light" />
                </Tooltip>
              )}
            </label>
            {!infoInSubLabel ? renderInfo(info) : null}
          </div>
        ) : null}
        {subLabel ? (
          <div className="mb-2 flex flex-wrap items-center space-x-1">
            <label className="text-base font-normal text-leland-gray-light">
              {subLabel}
            </label>
            {infoInSubLabel ? renderInfo(info) : null}
          </div>
        ) : null}
        <div
          className={`mt-2 flex items-center overflow-hidden rounded-lg border border-leland-gray-stroke transition duration-100 focus-within:border-leland-gray-extra-light`}
        >
          {subtlePrefix ? (
            <div
              className={`pointer-events-none whitespace-nowrap py-3 pl-4 pr-0.5 text-base `}
            >
              <span>{subtlePrefix}</span>
            </div>
          ) : null}
          <textarea
            className={`block w-full rounded-lg p-4 shadow-none outline-none outline-0 ring-0 placeholder:text-gray-400 ${inputClassName} ${
              resizeX
                ? resizeY
                  ? 'resize'
                  : 'resize-x'
                : resizeY
                  ? 'resize-y'
                  : 'resize-none'
            } ${subtlePrefix ? 'rounded-l-none pl-0' : ''}`}
            placeholder={placeholder}
            defaultValue={defaultText}
            maxLength={maxLength}
            rows={rows}
            {...register}
            onChange={async (e) => {
              await register.onChange(e);
              onChange?.(e);
            }}
          />
        </div>
        <div className="flex w-full">
          <div className="flex-1">
            {error?.message ? (
              <ErrorMessage errorMessage={error.message} />
            ) : null}
          </div>
          {maxLength ? (
            <p
              className={`mt-1 text-sm ${
                (currentLength ?? 0) > maxLength
                  ? 'text-leland-red'
                  : 'text-leland-gray-light'
              }`}
            >
              {currentLength}/{maxLength}
            </p>
          ) : null}
        </div>
      </label>
    </div>
  );
};

const INFO_CLASSNAME =
  'font-medium text-leland-primary text-base whitespace-nowrap';
const renderInfo = (info?: InfoButtonProps) => {
  if (!info) {
    return null;
  }

  const { label, href, onClick } = info;

  if (href) {
    return (
      <a
        className={INFO_CLASSNAME}
        href={href}
        target="_blank"
        rel="noreferrer"
      >
        {label}
      </a>
    );
  }

  return (
    <button className={INFO_CLASSNAME} onClick={onClick}>
      {label}
    </button>
  );
};

interface ErrorMessageProps {
  className?: string;
  errorMessage: string;
}

export const ErrorMessage = ({
  className,
  errorMessage,
}: ErrorMessageProps): ReactElement => {
  return (
    <p className={`mt-2 text-sm text-leland-red ${className ?? ''}`}>
      {errorMessage}
    </p>
  );
};
